import { Select } from "antd";
import {
  arrayToQueryParam,
  queryParamToArray,
  queryParamToArray2,
  stageArray,
} from "components/common/Constants";
import { getUniqueClient, useGetClientsQuery, useGetLocationQuery, useGetUniqueClientQuery } from "features/store";
import {
  AllPageTo1,
  ClientArray,
  LocationArray,
} from "features/thunks/FetchUsers";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const ClientFilter = () => {
  const dispatch = useDispatch();
  const { client_array } = useSelector((state) => state.user);
  const [selectedItems, setSelectedItems] = useState(
    queryParamToArray(client_array)
  );
  const handleselect = (data) => {
    const queryString = arrayToQueryParam("client", data);
    dispatch(AllPageTo1());
    dispatch(ClientArray(queryString));
    setSelectedItems(data);
    console.log(data);
    console.log(queryString);
  };
  const { isLoading: loloading, data: place } = useGetUniqueClientQuery();
  console.log("plac", place)
  return (
    <div className="filter-row">

      <label>Clients</label>
      <Select
        mode="multiple"
        showSearch
        filterOption={(input, option) =>
          option.label.toLowerCase().includes(input.toLowerCase())
        }
        placeholder="Select Client"
        value={selectedItems}
        onChange={handleselect}
        style={{
          minWidth: "200px",
          margin: "0px 10px",
        }}
        options={place?.map((item) => ({
          value: item?.id,
          label: item?.name,
        }))}
      />

    </div>
  );
};

export default ClientFilter;
