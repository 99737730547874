import { Select } from 'antd';
import { arrayToQueryParam, queryParamToArray, queryParamToArray2, stageArray } from 'components/common/Constants';
import { getUniqueLocation, useGetLocationQuery, useGetUniqueLocationQuery } from 'features/store';
import { AllPageTo1, LocationArray } from 'features/thunks/FetchUsers';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

const ReportLocationFilter = ({setLocationId}) => {
    const dispatch=useDispatch()
    const { location_array } = useSelector(
        (state) => state.user
      );
    const [selectedItems, setSelectedItems] = useState(queryParamToArray(location_array));
    const handleselect=(data)=>{
        const queryString = arrayToQueryParam("location", data);
        dispatch(AllPageTo1())
        dispatch(LocationArray(queryString))
        setSelectedItems(data)
        setLocationId(data)
    
      }
  const { isLoading: loloading, data: place } = useGetUniqueLocationQuery();

  return (
    <div className="filter-row">

        <label>Locations</label>

        <Select
          mode="multiple"
          placeholder="Select Location"
          filterOption={(input, option) =>
            option.label.toLowerCase().includes(input.toLowerCase())
          }
          value={selectedItems}
          onChange={handleselect}
          style={{
            minWidth: '200px',

            margin:"0px 10px"
          }}
          options={place?.map((item) => ({
            value: item?.id,
            label: item?.location,
          }))}
          />
    </div>
  )
}

export default ReportLocationFilter
