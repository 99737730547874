export const STAGES = {
  CREATION: "creation",
  APPROVAL: "approval",
  PRODUCTION: "production",
  FINISHING: "finishing",
  ACCOUNT: "account",
  COMPLETED: "completed",
};
export const USER_ROLES = {
  SUPERVISOR: "manufacturer",
  PRODUCTION: "production",
  FINISHING: "finishing",
  ACCOUNT: "account",
  CLIENT: "client",
  ADMIN: "admin",
};

export const PERMISSIONS = {
  CREATE: [USER_ROLES.ADMIN, USER_ROLES.SUPERVISOR],
  CLIENT_VISIBILITY: Object.values(USER_ROLES),
  UPDATE: [USER_ROLES.SUPERVISOR, USER_ROLES.ADMIN],
  SHOW_DASHBOARD: [USER_ROLES.ADMIN],
  SHOW_FIELDS: [USER_ROLES.FINISHING, USER_ROLES.PRODUCTION]
};

export const STAGES_LIST = Object.keys(STAGES).map((key) => STAGES[key]);

// edit -> admin, design
// view without client details -> cutting, production, account

export function areArraysOfObjectsEqual(arr1, arr2) {
  if (arr1?.length !== arr2?.length) {
    return false;
  }

  for (let i = 0; i < arr1?.length; i++) {
    const obj1 = arr1[i];
    const obj2 = arr2[i];

    const objKeys1 = Object.keys(obj1);
    const objKeys2 = Object.keys(obj2);

    if (objKeys1?.length !== objKeys2?.length) {
      return false;
    }

    for (const key of objKeys1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
  }

  return true;
}



export const isEqual=(array1,array2)=>{


  
  // Sort the arrays
  const sortedArray1 = array1.sort();
  const sortedArray2 = array2.sort();
  
  // Check if the sorted arrays are equal
  return JSON.stringify(sortedArray1) === JSON.stringify(sortedArray2);
} 


export function removeNaNFromArray(arr) {
  // Filter out NaN values from the array
  const filteredArray = arr.filter(value => !Number.isNaN(value));
  return filteredArray;
}
export  function arrayToQueryParam(paramName, values) {
    if (values.length === 0) {
      return ''; // Return an empty string if the array is empty
    }
    
    const queryParam = values.map(value => `${paramName}=${value}`).join('&');
    return `&${queryParam}`;
  }
  export function queryParamToArray2(queryParam) {
    if (!queryParam) {
      return []; // Return an empty array if there's no query param
    }
  
    // Split the query parameter string using '&' as the separator
    const paramsArray = queryParam.split('&');
  
    // Extract the values from the parameters
    const values = paramsArray.map(param => {
      const value = param.split('=')[1]; // Extract the value part after '='
      return value; // Convert the value to an integer or another appropriate type
    });
  console.log(values)
    return values.filter((item)=>item!==undefined);
  }
  export function queryParamToArray(queryParam) {
    if (!queryParam) {
      return []; // Return an empty array if there's no query param
    }
  
    // Split the query parameter string using '&' as the separator
    const paramsArray = queryParam?.split('&');
  
    // Extract the values from the parameters
    const values = paramsArray.map(param => {
      const value = param.split('=')[1]; // Extract the value part after '='
      return parseInt(value); // Convert the value to an integer or another appropriate type
    });
  
    return removeNaNFromArray(values);
  }

  export const stageArray=["creation", "approval", "production", "finishing", "account", "completed"]

  export const stageColors = {
    creation: "blue",
    approval: "gold",
    production: "purple",
    finishing: "cyan",
    account: "orange",
    completed: "green",
    rejected: "red", // If needed
  };
