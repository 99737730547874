import { Button, Tabs } from "antd";
import {
  PERMISSIONS,
  STAGES,
  USER_ROLES,
  isEqual,
} from "components/common/Constants";
import Loader from "components/loaders/Loader";
import {
  roleSelector,
  useGetCardsUnderApprovalQuery,
} from "features/apis/jobCardApi";
import { useFetchJobcardsQuery, useGetClientsQuery } from "features/store";
import React, { useEffect, useState } from "react";
import Collapsible from "react-collapsible";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import JobcardsTable from "./JobcardsTable";
import { getCardsForApproval } from "helpers/jobcardHelper";
import { LocationArray, StateTab } from "features/thunks/FetchUsers";
import FIlterComponents from "components/FIlterComponents";
import JobcardsTableClinet from "./JobcardsTableClinet";

const JobcardListing = () => {
  const {
    all_jobcard_page,
    under_jobcard_page,
    statetab,
    complete_jobcard_page,
    rejected_jobcard_page,
    approve_jobcard_page,
    location_array,
    client_array,
    stage_array,
  } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  // const { data: jobCardsForClient } = useGetCardsUnderApprovalQuery();
  const {
    data: allJobCards,
    refetch: allJobCardsFetch,
    isFetching: allJobisfetching
  } = useFetchJobcardsQuery({
    refetchOnMountOrArgChange: true,
    page: all_jobcard_page,
    array: location_array + client_array + stage_array,
  });
  console.log("allJobisfetching", allJobisfetching)
  const {
    data: underJobCards,
    refetch: underJobCardsFetch,
    isFetching: underJobisfetching
  } = useFetchJobcardsQuery({
    refetchOnMountOrArgChange: true,
    page: under_jobcard_page,
    extra: "&current_stage=approval",
    array: location_array + client_array + stage_array,
  });
  const {
    data: approvalJobCards,
    refetch: approvalJobCardsFetch,
    isFetching: approvalJobisfetching
  } = useFetchJobcardsQuery({
    refetchOnMountOrArgChange: true,
    page: approve_jobcard_page,
    extra: "&is_approved=true",
    array: location_array + client_array + stage_array,
  });
  const {
    data: rejectedJobCards,
    refetch: rejectedJobCardsFetch,
    isFetching: rejectedJobisfetching
  } = useFetchJobcardsQuery({
    refetchOnMountOrArgChange: true,
    page: rejected_jobcard_page,
    extra: "&is_rejected=true",
    array: location_array + client_array + stage_array,
  });
  const {
    data: completeJobCards,
    refetch: completeJobCardsFetch,
    isFetching: completeJobisfetching
  } = useFetchJobcardsQuery({
    refetchOnMountOrArgChange: true,
    page: complete_jobcard_page,
    extra: "&current_stage=completed",
    array: location_array + client_array + stage_array,
  });

  const { data: clients } = useGetClientsQuery();
  const selectedTab = useParams().tab;
  const { data: jobCardsForClient } = useGetCardsUnderApprovalQuery();
  const [tabs, setTabs] = useState();
  const [jobcardsToShow, setJobcardsToShow] = useState([]);
  const role = useSelector(roleSelector);
  const [selectedFilters, setSelectedFilters] = useState([]);
  useEffect(() => {
    // console.log(selectedFilters)
  }, [selectedFilters]);
  let jobCards = role == USER_ROLES.CLIENT ? jobCardsForClient : allJobCards;

  const navigate = useNavigate();
  // useEffect(() => {
  //   refetch();
  // }, [allJobCards]);

  useEffect(() => {
    if (!jobCards?.length) return;

    let _jobcards = jobCards;

    if ([USER_ROLES.PRODUCTION, USER_ROLES.FINISHING].includes(role))
      _jobcards = jobCards.filter((card) => card.current_stage === role);

    if (USER_ROLES.CLIENT === role)
      _jobcards = jobCards.filter(
        (card) => card.current_stage === STAGES.APPROVAL
      );

    setJobcardsToShow(_jobcards);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobCards]);

  if (!jobCards || !clients) return <Loader />;

  // const allshowJobCards = jobcardsToShow.filter((card) => card)
  // const approvedJobCards = jobcardsToShow.filter((card) => card.is_approved);
  // const completedJobCards = jobcardsToShow.filter(
  //   (card) => card.current_stage == STAGES.COMPLETED
  // );
  const underProcessCards = jobcardsToShow.filter(
    (card) => card.current_stage !== STAGES.COMPLETED
  );

  const TABS = [
    {
      label: `All`,
      key: "all",
      children: (
        <>
          {role == USER_ROLES.CLIENT ? (
            <JobcardsTableClinet
              tableKey="table_all"
              cards={underProcessCards.sort((a, b) => b.priority - a.priority)}
              clients={clients}
            />
          ) : (
            <JobcardsTable
              setSelectedFilters={setSelectedFilters}
              location_array={location_array}
              tableKey="table_all"
              cards={allJobCards?.results}
              count={allJobCards?.count}
              clients={clients}
              fetch={allJobisfetching}
              currentPage={all_jobcard_page}
            />
          )}
        </>
      ),
    },
    {
      label: `Under Approval`,
      key: "under",
      children: (
        <JobcardsTable
          setSelectedFilters={setSelectedFilters}
          location_array={location_array}
          tableKey="table_under"
          cards={underJobCards?.results}
          count={underJobCards?.count}
          fetch={underJobisfetching}

          currentPage={under_jobcard_page}
          clients={clients}
        />
      ),
    },
    {
      label: `Approved`,
      key: "approved",
      children: (
        <JobcardsTable
          setSelectedFilters={setSelectedFilters}
          location_array={location_array}
          tableKey={"table_approved"}
          cards={approvalJobCards?.results}
          fetch={approvalJobisfetching}

          count={approvalJobCards?.count}
          currentPage={approve_jobcard_page}
          clients={clients}
        />
      ),
    },
    {
      label: `Rejected`,
      key: "rejected",
      children: (
        <JobcardsTable
          setSelectedFilters={setSelectedFilters}
          location_array={location_array}
          tableKey={"table_rejected"}
          cards={rejectedJobCards?.results}
          fetch={rejectedJobisfetching}

          count={rejectedJobCards?.count}
          currentPage={rejected_jobcard_page}
          clients={clients}
        />
      ),
    },
    {
      label: `Completed`,
      key: "completed",
      children: (
        <JobcardsTable
          setSelectedFilters={setSelectedFilters}
          location_array={location_array}
          tableKey={"table_completed"}
          cards={completeJobCards?.results}
          count={completeJobCards?.count}
          fetch={completeJobisfetching}

          currentPage={complete_jobcard_page}
          clients={clients}
        />
      ),
    },
  ];

  const JOBCARDS_TABS = [
    USER_ROLES.CLIENT,
    USER_ROLES.PRODUCTION,
    USER_ROLES.FINISHING,
  ].includes(role)
    ? [TABS[0]]
    : TABS;
  return (
    <Style>
      {PERMISSIONS.CREATE.includes(role) && (
        <Button
          style={{
            margin: "0rem",
            background: "var(--color-primary)",
            color: "var(--light)",
            fontSize: "1.2rem",
            padding: "0.5rem 2rem",
            borderRadius: ".3rem",
            width: "fit-content",
            height: "fit-content",
          }}
          type="default"
          onClick={() => navigate("/create")}
        >
          Create A New Job Card
        </Button>
      )}
      <FIlterComponents />
      <Tabs
        onChange={(data) => {
          if (data === "under") {
            underJobCardsFetch();
          }
          if (data === "approved") {
            approvalJobCardsFetch();
          }
          if (data === "rejected") {
            rejectedJobCardsFetch();
          }
          if (data === "completed") {
            completeJobCardsFetch();
          }
          dispatch(StateTab(data));
        }}
        defaultActiveKey={selectedTab || statetab}
        centered
        items={JOBCARDS_TABS}
      />
    </Style>
  );
};

const Style = styled.div`
  padding: 2rem 1rem;
  table {
    border-collapse: collapse;
    .creation {
      border-left: 4px solid rgba(255, 255, 0, 0.5);
    }
    .finishing {
      border-left: 4px solid rgba(255, 0, 0, 0.5);
    }
    .approval {
      border-left: 4px solid rgba(0, 0, 255, 0.5);
    }
    .account {
      border-left: 4px solid rgba(0, 255, 255, 0.5);
    }
    .completed {
      border-left: 4px solid rgba(0, 255, 0, 0.5);
    }
  }

  .priority-0 {
    border-left: 4px solid grey;
    :hover {
      td {
        background: grey !important;
      }
    }
  }

  .priority-1 {
    border-left: 4px solid #00ff2a;
    :hover {
      td {
        background: #00ff2a !important;
      }
    }
  }

  .priority-2 {
    border-left: 4px solid #00ccff;
    :hover {
      td {
        background: #00ccff !important;
      }
    }
  }

  .priority-3 {
    border-left: 4px solid #f5864f;
    :hover {
      td {
        background: #f5864f !important;
      }
    }
  }

  .priority-4 {
    border-left: 4px solid #ff3c01;
    :hover {
      td {
        background: #ff3c01 !important;
      }
    }
  }
`;

export default JobcardListing;
