import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Row,
  Space,
  Statistic,
  Table,
  Tag,
} from "antd";
import {
  PERMISSIONS,
  STAGES,
  STAGES_LIST,
  USER_ROLES,
} from "components/common/Constants";
import { url } from "components/common/api";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { roleSelector } from "features/apis/jobCardApi";
import moment from "moment/moment";
import { CSVLink, CSVDownload } from "react-csv";
import { getFormalName } from "../common/Services";
import styled from "styled-components";
import Skeleton from "components/common/Skeleton";
import axios from "axios";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CheckCircleFilled,
  CheckCircleOutlined,
  ScissorOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import {
  getCardsApprovedByClients,
  getCardsForApproval,
  getCardsInAccount,
  getCardsInCreation,
  getCardsInFinishing,
  getCardsInProduction,
  getCardsRejectedByClients,
  getCompletedJobCards,
} from "helpers/jobcardHelper";
import Stats from "./Stats";
import FIlterComponentsReport from "components/FIlterComponentsReport";
import LocationFilter from "components/filterButtons/LocationFilter";
import ReportLocationFilter from "components/filterButtons/ReportLocationFilter";

// const fetchJbd = async (from = "2022-11-02", to = "2022-11-02", locationId = "", userLocation) => {
//   const response = await axios.get(`${url}/api/filterbydate`, {
//     params: {
//       fromdate: from,
//       enddate: to,
//       location: locationId,
//     },
//   });

//   return response.status === 200 ? response.data : false;
// };
const formatLocationParams = (locations) => {
  // Ensure locations are formatted as an array of objects with a "location" key
  if (Array.isArray(locations)) {
    return locations.map(location => ({ location }));
  }
  return [{ location: locations }];
};

const locationArray = [
  { id: 1, location: "vasai" },
  { id: 2, location: "swargate" },
  { id: 3, location: "warje" },
  { id: 4, location: "mumbai" }
];

const fetchJbd = async (from = "2022-11-02", to = "2022-11-02", locationId = "", userLocation) => {
  console.log("User location:", userLocation);

  let finalLocationIds = locationId;

  // If locationId is empty, find userLocation's corresponding ID
  if (!locationId && userLocation) {
    const matchedLocation = locationArray.find(loc => loc.location.toLowerCase() === userLocation.toLowerCase());
    if (matchedLocation) {
      finalLocationIds = matchedLocation.id;
    }
  }

  // Convert locationId into an array if it's a single value
  if (!Array.isArray(finalLocationIds)) {
    finalLocationIds = [finalLocationIds];
  }

  // Format location parameters
  const locationParams = formatLocationParams(finalLocationIds);

  // Construct query params without converting `location` into an array
  const params = {
    fromdate: from,
    enddate: to,
  };

  // Convert multiple locations into separate `location` query parameters
  const queryString = new URLSearchParams();
  queryString.append("fromdate", from);
  queryString.append("enddate", to);
  locationParams.forEach(({ location }) => {
    queryString.append("location", location);
  });

  console.log("API Params:", queryString.toString()); // Debugging

  try {
    const response = await axios.get(`${url}/api/filterbydate?${queryString.toString()}`);
    return response.status === 200 ? response.data : false;
  } catch (error) {
    console.error("Error fetching data:", error);
    return false;
  }
};







function JobCardBetweenDates(props) {
  const [locationId, setLocationId] = useState();
  const navigate = useNavigate();
  const role = useSelector(roleSelector);
  const { user, userToken, checkAuthLoading } = useSelector(
    (state) => state.user
  );
  const [cards, setCards] = React.useState([]);
  const [tableCards, setTableCards] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [tableColumns, setTableColumns] = React.useState();

  const fetchCards = async (from, to, locationId) => {
    setLoading(true);
    const cards = await fetchJbd(from, to, locationId, user?.location);
    console.log(cards);
    if (cards) setCards(cards);
    else alert("Failed to fetch job cards between the dates");
    setLoading(false);
  };
  // useEffect(() => {
  //   if (locationId) {
  //     const from = moment().startOf("day").format("YYYY-MM-DD"); // Default start date
  //     const to = moment().endOf("day").format("YYYY-MM-DD"); // Default end date
  //     fetchCards(from, to);
  //   }
  // }, [locationId]); // Runs when locationId changes


  const handleFinish = (values) => {
    const from = values.from.format("YYYY-MM-DD");
    const to = values.to.format("YYYY-MM-DD");
    console.log(from, to, locationId);
    fetchCards(from, to, locationId);
  };
  const client_namesss = [...new Set(cards.map((card) => card.Party_name))];
  const clientSet = new Set(cards?.map((item) => item.client.name));
  // console.log('clientSet: ', clientSet);

  const headers = [
    { label: "Job Card No.", key: "id" },
    { label: "Date", key: "Date" },
    { label: "Customer Name", key: "client.name" },
    { label: "Location", key: "location.location" },
    { label: "Quantity", key: "Quantity" },
    { label: "Design Name", key: "Design_name" },
    { label: "Rate", key: "rate" },
    { label: "Delivery Date", key: "job_out_field" },
    { label: "Stitch Count", key: "Stitches" },
    // { label: "Style", key: "style" },
    { label: "Current Stage", key: "current_stage" },
    { label: "Supervisor Date", key: "manufacturer_update_date" },
    { label: "Supervisor Time", key: "manufacturer_update_time" },
    { label: "Client Date", key: "client_update_date" },
    { label: "Client Time", key: "client_update_time" },
    { label: "Production Date", key: "Production_update_date" },
    { label: "Production Time", key: "Production_update_time" },
    { label: "Finishing Date", key: "Finishing_update_date" },
    { label: "Finishing Time", key: "Finishing_update_time" },
    { label: "Account Date", key: "Account_update_date" },
    { label: "Account Time", key: "Account_update_time" },
    { label: "Admin Date", key: "admin_update_date" },
    { label: "Admin  Time", key: "admin_update_time" },
  ];
  const locationSet = new Set(cards?.map((item) => item.location.location));
  // console.log('locationSet: ', locationSet);
  const dataSource = cards.map((card) => ({
    ...card,
    client_company: card.Party_name,
    current_stage:
      card.current_stage == null ? STAGES.CREATION : card.current_stage,
  }));

  const columns = [
    {
      title: "Job Card No.",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => parseInt(a.id) - parseInt(b.id),
    },
    {
      title: "Client Company",
      dataIndex: ["client", "name"],
      key: "client",
      // filteredValue: filteredInfo.client_company || null,
      filters: [...clientSet].map((name) => ({
        text: name,
        value: name,
      })),
      //  onFilter: (value, record) => record.client_namesss.includes(value),
      onFilter: (value, record) => record.client.name?.indexOf(value) === 0,
      filterSearch: true,
      // sorter: (a, b) => a.client_company.length - b.client_company.length,
      // sortOrder: sortedInfo.columnKey === 'client_company' ? sortedInfo.order : null,
      // ellipsis: true,
    },
    {
      title: "Location",
      dataIndex: ["location", "location"],
      key: "client",
      // filteredValue: filteredInfo.client_company || null,
      filters: [...locationSet].map((name) => ({
        text: name,
        value: name,
      })),
      onFilter: (value, record) =>
        record.location.location?.indexOf(value) === 0,
    },
    {
      title: "Stitches",
      dataIndex: "Stitches",
      key: "Stitches",
    },
    {
      title: "Last Updated By",
      dataIndex: "Last_update",
      key: "Last_update",
    },
    {
      title: "Quantity",
      dataIndex: "Quantity",
      key: "Quantity",
    },
    {
      title: "Current Stage",
      dataIndex: "current_stage",
      key: "current_stage",
      filters: STAGES_LIST.map((stage) => ({
        text: getFormalName(stage),
        value: stage,
      })),
      onFilter: (value, record) => record.current_stage.indexOf(value) === 0,
      render: (text, record) => {
        return getFormalName(text);
      },
    },
    {
      title: "Client Status",
      dataIndex: "is_rejected",
      key: "client_status",
      render: (text, record) => {
        return (
          <>
            {record.is_rejected && <Tag color="red">Rejected</Tag>}
            {record.is_approved && <Tag color="green">Approved</Tag>}
          </> //
        );
      },
    },
    {
      title: "Production",
      // dataIndex: ["Production_update_date", "Production_update_time"],
      key: ["Production_update_date", "Production_update_time"],
      render: (text, record) => (
        <div>
          <p>{record.Production_update_date}</p>
          <p>{record.Production_update_time}</p>
        </div>
      ),
    },
    {
      title: "Client",
      // dataIndex: "client_update_date",
      key: ["client_update_date", "client_update_time"],
      render: (text, record) => (
        <div>
          <p>{record.client_update_date}</p>
          <p>{record.client_update_time}</p>
        </div>
      ),
    },
    {
      title: "Finishing",
      // dataIndex: "Finishing_update_date",
      key: ["Finishing_update_date", "Finishing_update_time"],
      render: (text, record) => (
        <div>
          <p>{record.Finishing_update_date}</p>
          <p>{record.Finishing_update_time}</p>
        </div>
      ),
    },
    {
      title: "Supervisor",
      // dataIndex: "manufacturer_update_date",
      key: ["manufacturer_update_date", "manufacturer_update_time"],
      render: (text, record) => (
        <div>
          <p>{record.manufacturer_update_date}</p>
          <p>{record.manufacturer_update_time}</p>
        </div>
      ),
    },
    {
      title: "Admin",
      key: ["admin_update_date", "admin_update_time"],
      render: (text, record) => (
        <div>
          <p>{record.admin_update_date}</p>
          <p>{record.admin_update_time}</p>
        </div>
      ),
      // dataIndex: ["admin_update_date", "admin_update_time"],
    },
    {
      title: "Account",
      // dataIndex: "Account_update_date",
      key: ["Account_update_date", "Account_update_time"],
      render: (text, record) => (
        <div>
          <p>{record.Account_update_date}</p>
          <p>{record.Account_update_time}</p>
        </div>
      ),
    },
  ]; //

  useEffect(() => {
    let _table_columns = columns;

    if (!PERMISSIONS.UPDATE.includes(role))
      _table_columns = _table_columns.filter(
        (column) => column.title !== "Update"
      );

    if ([USER_ROLES.CLIENT].includes(role))
      _table_columns = _table_columns.filter(
        (column) =>
          column.title !== "Review" &&
          column.title !== "Forward" &&
          column.title !== "Client Status" &&
          column.title !== "Last Updated By" &&
          column.title !== "Current Stage" &&
          column.title !== "Client Company"
      );

    setTableColumns(_table_columns);
  }, [role, clientSet, locationSet]);


  return (
    <>
      <div>
        <div className="title">
          <h2 style={{ textAlign: "center", fontSize: "2rem" }}>Report</h2>
        </div>
        <Style>
          <Stats cards={cards} />
          <div>
            <div className="top">
              <Form layout="inline" onFinish={handleFinish}>
                <Form.Item label="From Date" name="from">
                  <DatePicker format={"DD/MM/YYYY"} />
                </Form.Item>
                <Form.Item label="To Date" name="to">
                  <DatePicker format={"DD/MM/YYYY"} />
                </Form.Item>
                {user?.role === "admin" ?  <ReportLocationFilter  setLocationId={setLocationId} /> : null}
                {/* submit button */}
                <Form.Item style={{ textAlign: "center" }}>
                  <Button type="primary" htmlType="submit" size="middle">
                    Search
                  </Button>
                </Form.Item>
                
                <Button>
                  <CSVLink
                    className="export_to_excel"
                    data={cards.map((card) => {
                      return {
                        ...card,
                        "client.name": card.client.name,
                      };
                    })}
                    headers={headers}
                    filename={"keshfashion.csv"}>
                    Export To Excel
                  </CSVLink>
                </Button>
              </Form>
            </div>
          </div>
        </Style>

        <div>
          {!loading ? (
            <Table
              style={{ margin: "0 2rem" }}
              dataSource={dataSource}
              columns={tableColumns}
              rowClassName={"table-row"}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    navigate(`/detail/${record.id}`);
                  },
                  className: record.current_stage,
                };
              }}
            />
          ) : (
            <Skeleton times={3} />
          )}
        </div>
      </div>
    </> //
  );
}

export default JobCardBetweenDates;
const Style = styled.div`
  margin: 0 2rem;
  .skeleton-with-100 {
    width: 100%;
  }
  .top {
    display: flex;
    padding: 2rem 0;
  }
  .title {
    font-size: 1.8rem;
    font-weight: bold;
  }
`;
