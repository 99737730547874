import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Switch,
  Checkbox,
  Upload,
  Space,
  Col,
  message,
  Slider,
} from "antd";
import styled from "styled-components";
import SelectType from "../molecules/SelectType";
import moment from "moment/moment";
import { STAGES, USER_ROLES } from "../common/Constants";
import {
  useCreateJobcardMutation,
  useGetClientsQuery,
  useGetDropdownsQuery,
  useGetLocationQuery,
  useUpdateJobcardMutation,
} from "features/store";
import Loader from "components/loaders/Loader";
import ImageViewer from "components/molecules/ImageViewer";
import { useSelector } from "react-redux";
import { roleSelector } from "features/apis/jobCardApi";
import { VerifyUser } from "components/common/VerifyUser";
import { useNavigate } from "react-router-dom";

const handleFormSubmit = (values, createJobcard) => {
  const formatedValues = {
    ...values,
    // Date: values.Date?.format("YYYY-MM-DD") || "",
    Job_in_time: values.Job_in_time?.format("YYYY-MM-DD") || "",
    job_out_field: values.job_out_field?.format("YYYY-MM-DD") || "",
    current_stage: STAGES.CREATION,
    image: values.image?.target.files[0],
    // image2: values.image2?.target.files[0],
  };

  createJobcard(formatedValues);
};

const handleFormUpdate = (values, updateJobcard) => {
  const formatedValues = {
    ...values,
    // Date: values.Date?.format("YYYY-MM-DD") || "",
    Job_in_time: values.Job_in_time?.format("YYYY-MM-DD") || "",
    job_out_field: values.job_out_field?.format("YYYY-MM-DD") || "",
  };

  //if no file selected; then no need to update the image
  if (!values.image?.target?.files?.length > 0) delete formatedValues.image;
  // if (!values.image2?.target?.files?.length > 0) delete formatedValues.image2;
  else {
    formatedValues.image = values.image?.target.files[0];
    // formatedValues.image2 = values.image2?.target.files[0];
  }

  updateJobcard(formatedValues);
};

// drop downs

// design_placemnt_array
// fusing_array

const JobCardForm = ({ formValues }) => {
  const [initialValues, setInitialValues] = useState(null);
  const [render, setRender] = useState(false);
  const { isLoading: loading, data: clients } = useGetClientsQuery();
  

  const { isLoading: locLoading, data: location } = useGetLocationQuery();
  const { user } = useSelector((state) => state.user);
  const role = useSelector(roleSelector);
  const [form] = Form.useForm();
  const { isLoading: dropdownLoading, data: selections } =
    useGetDropdownsQuery();
  const navigate = useNavigate();
  const [createJobcard, createResponseInfo] = useCreateJobcardMutation();
  console.log('createJobcard: ', createJobcard);
  const [updateJobcard, updateResponseInfo] = useUpdateJobcardMutation();

  const showPriority = () => [USER_ROLES.ADMIN].includes(role);

  const showAdmin = () => showPriority();

  const userLocationId =
    role === USER_ROLES.ADMIN
      ? false
      : location?.find((loc) => loc.location === user.location)?.id;

  const {
    error: failedToUpdate,
    isSuccess: updateSuccess,
    isLoading: updating,
  } = updateResponseInfo;
  const {
    error,
    isSuccess: createSuccess,
    isLoading: creating,
  } = createResponseInfo;

  // {
  //   failedToUpdate && VerifyUser(failedToUpdate);
  // }
  if (error) {
    VerifyUser(error);
  }
  if (failedToUpdate) {
    VerifyUser(failedToUpdate);
  }

  if (error) message.error("Failed to create job cardsss");
  

  if (failedToUpdate) message.error("Failed to update job card");

  if (updateSuccess) {
    message.success("Jobcard updated successfully!");
    navigate("/jobcards");
  }

  if (createSuccess) {
    message.success("Jobcard created successfully!")
    navigate("/jobcards");
  };

  useEffect(() => {
    setRender(false);
    if (formValues) {
      setInitialValues({
        ...formValues,
        Job_in_time: formValues.Job_in_time
          ? moment(formValues.Job_in_time, "DD/MM/YYYY")
          : null,
        job_out_field: formValues.job_out_field
          ? moment(formValues.job_out_field, "DD/MM/YYYY")
          : null,
      });
    }
    setRender(true);
  }, [formValues]);
  

  useEffect(() => {
    setRender(false);
    if (selections) {
      setRender(true);
    }
  }, [selections]);

  return render &&
    !(locLoading || loading || dropdownLoading) &&
    Object.keys(selections).length > 0 ? (
    <Style>
      {(updating || creating) && <Loader />}
      <Form
        form={form}
        labelCol={{
          span: 4,
        }}
        labelWrap
        wrapperCol={{
          span: 14,
        }}
        layout="horizontal"
        onFinish={(values) => {
          if (formValues) {
            handleFormUpdate(
              {
                ...values,
                id: formValues.id,
                client: formValues.client,
                location: userLocationId ? userLocationId : formValues.location,
              },
              updateJobcard
            );
          } else
            handleFormSubmit(
              {
                ...values,
                location: userLocationId ? userLocationId : values.location,
              },
              createJobcard
            );
        }}
        initialValues={initialValues}>
        {showPriority() && (
          <Form.Item name="priority" label="Priority">
            <Slider
              style={{
                width: "500px",
                marginLeft: "2rem",
              }}
              marks={{
                0: {
                  style: {
                    color: "#1a1a1a",
                  },
                  label: "None",
                },
                1: {
                  style: {
                    color: "#00ff2a",
                  },
                  label: "Low",
                },
                2: {
                  style: {
                    color: "#00ccff",
                  },
                  label: "Medium",
                },
                3: {
                  style: {
                    color: "#f5864f",
                  },
                  label: "High",
                },
                4: {
                  style: {
                    color: "#ff3c01",
                  },
                  label: "Critical",
                },
              }}
              defaultValue={formValues?.priority}
              max={4}
            />
          </Form.Item>
        )}

        

        {/*     <Form.Item
          label="Ref. Challan No"
          name="Ref_Challan_no"
          >
          <Input />
        </Form.Item>
            */}
        <Form.Item
          label="Client"
          name="client"
          rules={[
            {
              required: true,
              message: "Required Field!",
            },
          ]}>
          <Select
            showSearch
            optionFilterProp="children"
            onChange={(value) => {}}
            onSearch={(value) => {}}>
            {clients?.map((client) => (
              <Select.Option value={client.id}>
                {client.name} {client.address && `, ${client.address}`}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Design Name"
          name="Design_name"
          rules={[
            {
              required: true,
              message: "Required Field!",
            },
          ]}>
          <Input />
        </Form.Item>
        {role === USER_ROLES.ADMIN && (
          <Form.Item
            label="Location"
            name="location"
            rules={[
              {
                required: true,
                message: "Required Field!",
              },
            ]}>
            <Select
              showSearch
              optionFilterProp="children"
              onChange={(value) => {}}
              onSearch={(value) => {}}>
              {location?.map((location) => (
                <Select.Option value={location.id}>
                  {location.location}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          label="Job Cordinator"
          name="Job_coordinator"
          rules={[
            {
              required: true,
              message: "Required Field!",
            },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="Mobile No."
          name="Mob"
          >
          <Input />
        </Form.Item>
        <Form.Item
          label="Rate"
          name="rate"
    
          >
          <Input />
        </Form.Item>

        <Form.Item
          label="Quantity"
          name="Quantity"
          rules={[
            {
              required: true,
              message: "Required Field!",
            },
          ]}>
          <InputNumber />
        </Form.Item>

        <Form.Item
          label="Stitches"
          name="Stitches"
         >
          <Input />
        </Form.Item>

        <SelectType
          label="Design placement"
          name="design_placement"
          rules={[
            {
              required: true,
              message: "Required Field!",
            },
          ]}
          options={selections.design_placemnt_array}
        />

        <div className="sub-section">
          <div className="title">Product</div>
          <div className="sub-section">
            <div className="title">T-Shirt</div>
            <div className="row">
              <Form.Item label="Front LC" name="tshirt_front_lc">
                <Input />
              </Form.Item>
              <Form.Item label="Front RC" name="tshirt_front_rc">
                <Input />
              </Form.Item>
              <Form.Item label="Front CC" name="tshirt_front_cc">
                <Input />
              </Form.Item>
              <Form.Item label="Back CC" name="tshirt_back_cc">
                <Input />
              </Form.Item>
              <Form.Item label="Front LC Palla" name="tshirt_front_lc_palla">
                <Input />
              </Form.Item>
              <Form.Item label="Front RC Palla" name="tshirt_front_rc_palla">
                <Input />
              </Form.Item>
              <Form.Item label="Left Sleeves" name="tshirt_left_sleeves">
                <Input />
              </Form.Item>
              <Form.Item label="Right Sleeves" name="tshirt_right_sleeves">
                <Input />
              </Form.Item>
              <Form.Item
                label=" Left Sleeves Palla"
                name="tshirt_left_sleeves_palla">
                <Input />
              </Form.Item>
              <Form.Item
                label="Right Sleeves Palla"
                name="tshirt_right_sleeves_palla">
                <Input />
              </Form.Item>
              <Form.Item label="Back" name="tshirt_back">
                <Input />
              </Form.Item>
              <Form.Item label="Back Palla" name="tshirt_back_palla">
                <Input />
              </Form.Item>
              <Form.Item
                label="Front Above Pocket"
                name="tshirt_front_above_pocket">
                <Input />
              </Form.Item>
            </div>
          </div>
          <div className="sub-section">
            <div className="title">Shirt</div>
            <div className="row">
              <Form.Item label="Front LC" name="shirt_front_lc">
                <Input />
              </Form.Item>
              <Form.Item label="Front RC" name="shirt_front_rc">
                <Input />
              </Form.Item>
              <Form.Item label="Front CC" name="shirt_front_cc">
                <Input />
              </Form.Item>
              <Form.Item label="Back CC" name="shirt_back_cc">
                <Input />
              </Form.Item>
              <Form.Item label="Front LC Palla" name="shirt_front_lc_palla">
                <Input />
              </Form.Item>
              <Form.Item label="Front RC Palla" name="shirt_front_rc_palla">
                <Input />
              </Form.Item>
              <Form.Item label="Left Sleeves" name="shirt_left_sleeves">
                <Input />
              </Form.Item>
              <Form.Item label="Right Sleeves" name="shirt_right_sleeves">
                <Input />
              </Form.Item>
              <Form.Item
                label=" Left Sleeves Palla"
                name="shirt_left_sleeves_palla">
                <Input />
              </Form.Item>
              <Form.Item
                label="Right Sleeves Palla"
                name="shirt_right_sleeves_palla">
                <Input />
              </Form.Item>
              <Form.Item label="Back" name="shirt_back">
                <Input />
              </Form.Item>
              <Form.Item label="Back Palla" name="shirt_back_palla">
                <Input />
              </Form.Item>
              <Form.Item
                label="Front Above Pocket"
                name="shirt_front_above_pocket">
                <Input />
              </Form.Item>
              <Form.Item label="Cuff" name="shirt_cuff">
                <Input />
              </Form.Item>
              <Form.Item label="Cuff Palla" name="shirt_cuff_palla">
                <Input />
              </Form.Item>
              <Form.Item label="Collar" name="shirt_collar">
                <Input />
              </Form.Item>
              <Form.Item label="Collar Palla" name="shirt_collar_palla">
                <Input />
              </Form.Item>
            </div>
          </div>
          <div className="sub-section">
            <div className="title">Cap</div>
            <div className="row">
              <Form.Item label="Front" name="cap_front">
                <Input />
              </Form.Item>
              <Form.Item label="Left Middle" name="cap_left_middle">
                <Input />
              </Form.Item>
              <Form.Item label="Right Middle" name="cap_right_middle">
                <Input />
              </Form.Item>
              <Form.Item label="Back" name="cap_back">
                <Input />
              </Form.Item>
              <Form.Item label="Velcro" name="cap_velcro">
                <Input />
              </Form.Item>
              <Form.Item label="Visor" name="cap_visor">
                <Input />
              </Form.Item>
              <Form.Item label="Back Left Side" name="cap_back_left_side">
                <Input />
              </Form.Item>
              <Form.Item label="Back Right Side" name="cap_back_right_side">
                <Input />
              </Form.Item>
              <Form.Item
                label="Back + Middle Right"
                name="cap_back_middle_right">
                <Input />
              </Form.Item>
              <Form.Item
                label=" Back + Middle Left"
                name="cap_back_middle_left">
                <Input />
              </Form.Item>
              <Form.Item label="Front Left" name="cap_fornt_left">
                <Input />
              </Form.Item>
              <Form.Item label="Front Right" name="cap_front_right">
                <Input />
              </Form.Item>
            </div>
          </div>

          <div className="sub-section">
            <div className="title">Bag</div>
            <div className="row">
              <Form.Item label="Bag Front" name="bag_front">
                <Input />
              </Form.Item>
            </div>
          </div>
          <div className="sub-section">
            <div className="title">Apron</div>
            <div className="row">
              <Form.Item label="Front LC" name="appron_front_rc">
                <Input />
              </Form.Item>
              <Form.Item label="Right LC" name="appron_right_lc">
                <Input />
              </Form.Item>
              <Form.Item label="Front Center" name="appron_front_center">
                <Input />
              </Form.Item>
            </div>
          </div>
          <div className="sub-section">
            <div className="title">Waist Coat</div>
            <div className="row">
              <Form.Item label="Left Sleeves" name="waist_coat_left_sleeves">
                <Input />
              </Form.Item>
              <Form.Item label="Right Sleeves" name="waist_coat_right_sleeves">
                <Input />
              </Form.Item>
            </div>
          </div>
          <div className="sub-section">
            <div className="title">Pant</div>
            <div className="row">
              <Form.Item label="Left Side" name="pant_left_side">
                <Input />
              </Form.Item>
              <Form.Item label="Right Side" name="pant_right_side">
                <Input />
              </Form.Item>
            </div>
          </div>
          <div className="sub-section">
            <div className="title">Pocket</div>
            <div className="row">
              <Form.Item label="Pocket Patti Center" name="pocket_patti_center">
                <Input />
              </Form.Item>
              <Form.Item
                label="Pocket Patti Pen Side"
                name="pocket_patti_pen_side">
                <Input />
              </Form.Item>
              <Form.Item label="Pocket Center" name="pocket_center">
                <Input />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="sub-section">
          <div className="title">Design Size</div>
          <div className="row">
            <Form.Item label="Width" name="Design_width">
              <Input />
            </Form.Item>
            <Form.Item label="Height" name="Design_height">
              <Input />
            </Form.Item>
            <Form.Item label="Ring Size" name="Ring_size">
              <Input />
            </Form.Item>
          </div>
        </div>

        <Form.Item label="Design Color Code" name="Design_color_code">
          <Input />
        </Form.Item>

        <SelectType
          label="Fusing"
          name="Fusing"
          options={selections.fusing_array}
        />

        <Form.Item
          rules={[
            {
              required: true,
              message: "Required Field!",
            },
          ]}
          label="Job In Time"
          name="Job_in_time">
          <DatePicker format={"YYYY-MM-DD"} />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Required Field!",
            },
          ]}
          label="Job Out Time"
          name="job_out_field">
          <DatePicker format={"YYYY-MM-DD"} />
        </Form.Item>

        <Form.Item label="Special Instructions" name="special_instruction">
          <Input />
        </Form.Item>

        <Space
          style={{
            margin: "auto",
            width: "100vw",
            // background: "red",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          {formValues && formValues.image && (
            <>
              <h4>Previously Selected Img 1</h4>
              <ImageViewer
                style={{ height: "100px", width: "100px" }}
                src={formValues.image}
              />
            </>
          )}
          {/* {formValues && formValues.image2 && (
            <>
              <h4>Previously Selected Img 1</h4>
              <ImageViewer
                style={{ height: "100px", width: "100px" }}
                src={formValues.image2}
              />
            </>
          )} */}
          <Form.Item
            name={"image"}
            label="Select New Image"
            valuePropName="filelist"
            wrapperCol={{ span: 24 }}
            labelCol={{ span: 12 }}>
            <input type={"file"} />
          </Form.Item>
          {/* <Form.Item
            name={"image2"}
            label="Select New Image"
            valuePropName="filelist"
            wrapperCol={{ span: 24 }}
            labelCol={{ span: 12 }}>
            <input type={"file"} />
          </Form.Item>*/}
        </Space>

        <Form.Item style={{ textAlign: "center" }}>
          <Button
            type="primary"
            style={{ padding: ".5rem 2rem", fontSize: "1rem" }}
            htmlType="submit"
            size="large">
            {formValues ? "Update Jobcard" : "Create Job Card"}
          </Button>
        </Form.Item>
      </Form>
    </Style>
  ) : (
    <Loader />
  );
};

const Style = styled.div`
  padding: 1rem;
  .loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 1rem;
    z-index: 999;
  }
  .section {
    /* width: fit-content; */
    margin-bottom: 20px;
    border: 1px solid #00000068;
    .title {
      padding: 1rem;
    }
  }
  .sub-section {
    border: 1px solid #97939368;
    margin: 1rem;
    padding: 1rem;
    .title {
      margin-bottom: 1rem;
    }
    .row {
      display: grid;
      grid-template-columns: 1fr 1fr;

      .ant-form-item-row {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        .ant-col-4 {
          max-width: 100%;
          width: 100%;
        }

        .ant-form-item-label-wrap {
          white-space: nowrap;
          width: 100%;
        }
      }
      @media only screen and (max-width: 600px) {
        grid-template-columns: 1fr;
        .ant-form-item-row {
          display: grid;
          grid-template-columns: 1fr;
        }
      }
    }
  }
`;

export default JobCardForm;
